.mypb-standalone-page {
  font-family: var(--font-family);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  overflow-y: scroll;

  &__margin {
    margin-top: 15vh;
 }
}
