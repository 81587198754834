body {

    .mypb-page {

        &::before {
            width: 100%;
            height: 20px;
            background-color: var(--color-admin);
            background-color: transparent;
            top: -20px;
            left: 0;
            right: 0;
            content: "";
            display: block;
            position: fixed;
            z-index: 100;
            transition: box-shadow 0.3s ease;
        }     
    
        &::after {
            width: 100%;
            height: 20px;
            background-color: var(--color-admin);
            background-color: transparent;
            left: 0;
            right: 0;
            bottom: -20px; 
            content: "";
            display: block;
            position: fixed;
            z-index: 100;
            transition: box-shadow 0.3s ease;
        }
    }

    &.mypb--is-edit-mode-enabled {
        
        .mypb-page {

            &::before, &::after {
                .box-shadow(0 0 30px #00000090);
            }  
        }
    }
}