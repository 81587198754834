html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--tpl-font-family);
  font-weight: var(--tpl-font-weight);
  font-size: var(--tpl-font-size);
  line-height: 1.5;
  color: var(--color-black-lighten);
  background-color: var(--color-white);
  padding: 0;
  margin: 0;

  &.blocked {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  cursor: pointer;
}

.mypb-page-main-block {
  min-height: 100vh;
}