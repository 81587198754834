
// CSS Variables
:root {

  // Font
  --font-family: 'Manrope', sans-serif;
  --font-light: 'Manrope', sans-serif;
  --font-bold: 'Manrope', sans-serif;
  --font-weight-light: 400;
  --font-weight: 400;
  --font-weight-bold: 600;
  --font-size: 18px;
  --font-size-small: 14px;

  // Admin Color
  --color-admin: #1D75FA;
  --color-admin-lighten: #3aadff;
  --color-admin-darken: #0857cd;
  
  // Main Color (Template Primary Color)
  --color-primary: #1D75FA;
  --color-primary-lighten: #3aadff;
  --color-primary-darken: #0857cd;

  // Colors
  --color-black: #000;
  --color-black-lighten: #2c2c2c;
  --color-black-darken: #000;

  --color-white: #FFF;
  --color-white-lighten: #FFF;
  --color-white-darken: #FFF;

  --color-silver: #e3e3e3;
  --color-silver-lighten: #F2F2F2;
  --color-silver-darken: #cfcfcf;

  --color-gray: #9197a6;
  --color-gray-lighten: #b4bac8;
  --color-gray-darken: #666b77;

  --color-red: #f40000;
  --color-blue: #41A1D5;
  --color-yellow: #FBD00C;
  --color-green: #89AE51;
  --color-pink: #c3325f;
  --color-orange: #f89406;
  --color-purple: #AE8ABF;

  // Default Title
  --title-default-font: var(--font-family);
  --title-default-size: 42px;
  --title-default-color: var(--color-black-lighten);
  --title-default-weight: 700;

  // Default Subtitle
  --subtitle-default-font: var(--font-family);
  --subtitle-default-size: 28px;
  --subtitle-default-color: var(--color-black);
  --subtitle-default-weight: 700;

  // Main Header
  --color-header-font-size: #FFF;
  --color-header: #FFF;
  --color-header-active: #dfdfdf;
  --color-header-bg: var(--color-admin);

  // Style
  --color-link: var(--color-admin-darken);
  --color-link-hover: var(--color-admin);
  --color-blockquote: var(--color-admin);

}

:root.dark {
  --bg-color: #121212;
  --text-color: #45ADFE;
}
