/**
 * All global styles for MyPageBuilder.
 * Everything imported here will apply to the entire application (visitors/editors/viewers mode)
 * and also on all domains and templates.
 */

// Vendor Styles (installed via npm)
@import "../node_modules/normalize.css/normalize.css";
@import "../node_modules/lesshat/lesshat";

// Vendor Styles (local)
@import "styles/vendor/fontawesome/less/fontawesome";
@import "styles/vendor/fontawesome/less/light";
@import "styles/vendor/fontawesome/less/brands";
@import "styles/vendor/fontawesome/less/solid";

// Base
@import "styles/main/_base";
@import "styles/main/_button";
@import "styles/main/_grid";
@import "styles/main/_edit-mode";
@import "styles/main/_swal";
@import "styles/main/_notification";
@import "styles/main/_form";
@import "styles/main/_richtext";
@import "styles/main/_lightbox";
@import "styles/main/_settings";
@import "styles/main/_shorthands";
@import "styles/main/_background";
@import "styles/main/_standalone-page";