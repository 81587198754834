.mypb-grid-modal {
  margin-top: 10%;
  .box-shadow(0 3px 40px -10px #00000040);
  .border-radius(3px);
}

.mypb-grid-small {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}    

.mypb-grid-wide {
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mypb-page-grid, .mypb-subpage, .mypb-footer {

  .mypb-block, .col-block {
    margin-left: auto;
    margin-right: auto;
    width: 1200px;
    padding-right: 400px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}  

@media (max-width: 1199px) {

  .mypb-page-grid, .mypb-subpage, .mypb-footer {

    .mypb-block, .col-block {
      margin-left: 0;
      margin-right: 0;
      width: auto;
      padding-right: unset;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  } 
}

@media (max-width: 920px) {

  .mypb-grid-wide {
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
  }  
}

@media (max-width: 620px) {

  .mypb-grid-small {
    margin-left: 10px;
    margin-right: 10px;
  }  
}
