.btn-close, .btn-back, .btn-round, .btn-round-secondary {
  width: 44px;
  height: 44px;
  display : flex;
  justify-content : center;
  align-items : center;
  background-color: #cfcfcf50;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  .border-radius(44px);

  &::before {
    content: '\f00d';
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    color: var(--color-black-lighten);
    font-size: 18px;
    font-weight: 300;
  }

  &:hover, &:focus {
    animation: hoverScaleBtn 0.4s ease-in-out forwards 1;
  }
}

.btn-back {

  &::before {
    font-size: 22px;
    content: '\f177';
  }
}

.btn-round {
  color: white;
  background: var(--color-admin); 
  background: -moz-linear-gradient(top,  var(--color-admin-lighten) 0%, var(--color-admin-darken) 100%);
  background: -webkit-linear-gradient(top,  var(--color-admin-lighten) 0%,var(--color-admin-darken) 100%);
  background: linear-gradient(to bottom,  var(--color-admin-lighten) 0%,var(--color-admin-darken) 100%);
  cursor: pointer;

  &::before {
    color: white;
    font-size: 22px;
    content: '\f177';
  }
}

.btn-round-secondary {
  color: var(--color-admin);
  border: solid 2px var(--color-admin);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #FFFFFF;
  /*
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);*/
  cursor: pointer;

  &::before {
    color: var(--color-admin);
    font-size: 22px;
    content: '\f177';
  }
}

.btn-round-sub {
  all: unset;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  transition: all 0.2s ease;
  cursor: pointer;

  i {
    font-size: 22px;
  }

  &:hover, &:focus {
    .opacity(0.6);
  }
}

@keyframes hoverScaleBtn {
  0% { transform: scale(1); } 
  50% { 
      transform: scale(0.95); 
  } 
  100% { transform: scale(1); } 
} 

.btn-primary {
  all: unset;
  color: white;
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  background-color: var(--color-admin);
  border: solid 2px var(--color-admin);
  padding: 10px 30px;
  margin-right: 15px;
  display: inline-block;
  line-height: normal;
  cursor: pointer;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  white-space: nowrap;
  .border-radius(4px);

  i {
    margin-right: 3px;
  }
  
  &:hover, &:focus {
    color: white;
    background-color: var(--color-admin-darken);
    border: solid 2px var(--color-admin-darken);
  }    
}
  
.btn-secondary {
  .btn-primary;
  color: var(--color-admin);
  background-color: transparent;

  &:hover, &:focus {
    color: var(--color-admin-darken);
    background-color: transparent;
    border: solid 2px var(--color-admin-darken);
  }
} 

.btn-link {
    .btn-primary;
    color: var(--color-admin);
    padding: 10px 15px;
    background-color: transparent;
    border-color: transparent;
  
    &:hover, &:focus {
      color: var(--color-admin-darken);
      background-color: transparent;
      border-color: transparent;
    }

    &.btn-small {
      background-color: transparent;
      border-color: transparent;
      color: var(--color-gray);
    }
}
  
.btn-small {
  .btn-primary;
  font-size: var(--font-size-small);
  color: white;
  background-color: var(--color-admin);
  border: solid 2px var(--color-admin);
  padding: 2px 6px;
  margin-right: 10px;
  display: inline-block;
  line-height: 19px;
  height: 26px;
  cursor: pointer;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  .border-radius(3px);
  
  &:hover, &:focus {
    color: white;
    background-color: var(--color-admin-darken);
    border: solid 2px var(--color-admin-darken);
  }  
}

.btn-small-primary {
  .btn-small;
  color: white;
  border-color: var(--color-admin);
  background-color: var(--color-admin);
} 

.btn-small-secondary {
  .btn-small;
  color: black;
  border-color: var(--color-silver-lighten);
  background-color: var(--color-silver-lighten);
}  

.btn-logout {
    color: var(--color-admin);
    cursor: pointer;
  
    &::before {
      content: '\f08b';
      font-family: "Font Awesome 5 Pro";
      font-style: normal;
      font-size: 18px;
      font-weight: 400;
      display: inline-block;
      position: relative;
      margin-right: 5px;
    }
  
    &:hover, &:focus {
      color: var(--color-admin-darken);
    }
  }
  
  .btn[disabled] {
    opacity: 0.5;
    cursor: default;
  }
  
  .btn i {
    margin-left: 4px;
  }
  