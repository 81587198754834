.lb-data {

  .lb-controlContainer {
    float: none;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 11;

    .lb-closeContainer {

      .lb-close {
        background: none;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        -webkit-border-radius: 44px;
        -webkit-background-clip: padding-box;
        -moz-border-radius: 44px;
        -moz-background-clip: padding;
        border-radius: 44px;
        background-clip: padding-box;

        &::before {
          content: '\f00d';
          font-family: "Font Awesome 5 Pro";
          font-style: normal;
          color: var(--color-black-lighten);
          font-size: 18px;
          font-weight: 300;
        }
      }
    }    
  }
}