
.mypb-admin-style {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
}

a {
  text-decoration: none;
  color: var(--color-link);

  &:hover {
    color: var(--color-link-hover);
  }

  &:active, &:focus {
    text-decoration: none;
  }
}

.text-lead {
  color: var(--color-gray-darken);
  font-size: 21px;
}

h1, h2, .mypb-block-title, textarea.mypb-block-title, textarea.mypb-block-blog-title {
  color: var(--title-default-color);
  font-family: var(--title-default-font);
  font-size: var(--title-default-size);
  font-weight: var(--title-default-weight);
  line-height: 1.125em;
  letter-spacing: -.03em;
  margin: 0;
  margin-bottom: 10px;
}

h2 {
  font-size: var(--subtitle-default-size);
  margin-top: 34px;
  letter-spacing: -.03em;
}

h3 {
  color: var(--title-default-color);
  font-family: var(--title-default-font);
  font-size: 37px;
  font-weight: var(--title-default-weight);
  line-height: 1.125em;
  letter-spacing: -.03em;
  margin: 0;
}

h4 {
  color: var(--title-default-color);
  font-family: var(--title-default-font);
  font-size: 28px;
  font-weight: var(--title-default-weight);
  line-height: 1.125em;
  letter-spacing: -.03em;
  margin: 0;
}

h5 {
  color: var(--title-default-color);
  font-family: var(--title-default-font);
  font-size: 21px;
  font-weight: var(--title-default-weight);
  line-height: 1.125em;
  margin: 0;
}

h6 {
  color: var(--title-default-color);
  font-family: var(--title-default-font);
  font-size: 18px;
  font-weight: var(--title-default-weight);
  line-height: 1.125em;
  margin: 0;
}

h1, h2 {

  &.subtitle {
    color: var(--subtitle-default-color);
    font-family: var(--subtitle-default-font);
    font-size: var(--subtitle-default-size);
    font-weight: var(--subtitle-default-weight);
  }
}

h3, h4, h5, h6 {

  &.subtitle {
    color: var(--subtitle-default-color);
    font-family: var(--subtitle-default-font);
    font-weight: var(--subtitle-default-weight);
  }
}

@media (max-width: 575px) {

  h1, h2, .mypb-block-title {
    font-size: 28px;
  }  

  h2 {
    font-size: 21px;
    margin-top: 25px;
  }
}
