


// Background Gradient Animation
// <div class="mypb-bg"><div class="mypb-bg-inner"></div></div>
@keyframes gradient {
    0% {background-position: 0%}
    100% {background-position: 100%}
}

@use postcss-cssnext;

:root {
  --primary: #ffffff;
  --accent: #1D75FA;
}

.mypb-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.mypb-bg .mypb-bg-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(300deg,#b5e1fb,#ffffff,#cce9fd); //#E7F3FF
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}