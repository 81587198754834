.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.text-small {
  font-size: var(--tpl-font-size-small);
}

.text-lighten, .text-light {
  color: var(--color-gray) !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.height-150 {
  min-height: 150px;
}

.height-200 {
  min-height: 200px;
}

.height-250 {
  min-height: 250px;
}

.height-300 {
  min-height: 300px;
}

.height-350 {
  min-height: 350px;
}

.height-400 {
  min-height: 400px;
}

.gap10 {
  height: 10px;
}

.gap20 {
  height: 20px;
}

.gap30 {
  height: 30px;
}

.gap40 {
  height: 40px;
}

.gap50 {
  height: 50px;
}

.gap60 {
  height: 60px;
}

.gap70 {
  height: 70px;
}

.gap80 {
  height: 80px;
}

.gap90 {
  height: 90px;
}

.gap100 {
  height: 100px;
}

.gap120 {
  height: 120px;
}

.gap150 {
  height: 150px;
}

.gap160 {
  height: 160px;
}

.gap200 {
  height: 200px;
}

.gap300 {
  height: 300px;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.clearfix {
  clear: both;
}
