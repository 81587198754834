.swal2-container {
  font-family: var(--font-family);

  > .swal2-popup {

    .swal2-title, .swal2-html-container {
      color: black;
      font-weight: var(--font-weight);
      padding: 0px 20px;
    }

    .swal2-icon {
      border-color: var(--color-admin);
      color: var(--color-admin);

      .swal2-success-line-tip, .swal2-success-line-long {
        background-color: var(--color-admin);
      }

      .swal2-success-ring {
        border: 0.25em solid var(--color-admin);
        .opacity(0.3);
      }

      .swal2-x-mark {

         > * {
           background-color: var(--color-admin);
         }
      }
    }

    .swal2-styled {

      &.swal2-confirm {
        background-color: var(--color-admin);
        border: solid 2px var(--color-admin);
      }

      &.swal2-cancel {
        color: var(--color-admin);
        border: solid 2px var(--color-admin);
        background-color: transparent;
      }
    }

    
  }
}
