.form-wrapper {
  background-color: var(--color-white);
  padding: 20px;
  margin-bottom: 10px;
  .border-radius(4px);
  .box-shadow(0 3px 20px 0 #00000020);
  transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
  position: relative;
  line-height: normal;

  form > .form-group:first-child {
    margin-top: 0;
  }
}

.video .form-wrapper, .picture .form-wrapper {
  padding: 10px;
}

.form-input-field {
  font-family: var(--font-family);
  color: black;
  background-color: white;
  border: solid 2px var(--color-silver);
  padding: 4px 8px;
  width: 100%;
  .border-radius(3px);
  z-index: 2;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s ease;
}

.form-input-field-small {
  font-family: var(--font-family);
  color: black;
  background-color: white;
  border: solid 2px var(--color-silver);
  padding: 1px 8px;
  width: auto;
  height: 26px;
  .border-radius(3px);
  z-index: 2;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s ease;
}

.form-group {
  margin-top: 15px;
  font-family: var(--font-family);

  &-1-3 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
  }

  &-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  label {
    color: var(--color-gray);
    font-size: var(--font-size-small);
    display: block;
  }

  input[type=checkbox] ~ label {
    display: inline-block;
    margin-left: 10px;
  }

  input[type=text], input[type=password], input[type=email], input[type=number], textarea {
    .form-input-field;
  }

  .form-text.text-muted {
    color: var(--color-red);
    line-height: normal;
    display: inline-block;
  }

  &.form-group-large {

    input[type=text], input[type=password], input[type=email], input[type=number], textarea {
      padding: 12px;
      border-color: var(--color-gray-darken);
    }
  }
}
