.mypb-notification {
    display: none !important; // Temp
    position: fixed;
    left: 10px;
    bottom: 10px;
    width: 300px;
    max-width: 100%;
    padding: 30px 70px 30px 30px;
    .border-radius(8px);
    .box-shadow(0 3px 20px -5px #00000040);
    transition: all ease 0.5s;
    z-index: 10000;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    &__close {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 0;
        right: 0;
        display : flex;
        justify-content : center;
        align-items : center;
        cursor: pointer;

        &::before {
            color: var(--color-gray-lighten);
            content: '\f00d';
            font-family: "Font Awesome 5 Pro";
            font-style: normal;
            font-size: 18px;
            font-weight: 300;
        }
    }
    
}